import { GoogleMap, Marker } from 'vue3-google-map';
import { Loader as GmapLoader } from '@googlemaps/js-api-loader';
import _debounce from 'lodash/debounce';
import Loader from '../../Loader/Loader.vue';

export default {
  props: {
    context: {
      type: Object,
    },
  },

  components: {
    GoogleMap,
    Marker,
    Loader,
  },

  mounted() {
    const loader = new GmapLoader({
      apiKey: 'AIzaSyA7Vquofh7lxKL_m0LNENOzvtzz1zw9Jj8',
      version: 'weekly',
    });

    this.apiPromise = loader.importLibrary('maps');

    loader.importLibrary('geocoding').then(({ Geocoder }) => {
      this.geocoder = new Geocoder();
      this.geocodeAddress();
    });

    this.$formkit.get('kiw_form--address_street').on('input', () => {
      this.geocodeAddress();
    });
    this.$formkit.get('kiw_form--address_number').on('input', () => {
      this.geocodeAddress();
    });
    this.$formkit.get('kiw_form--postcode').on('input', () => {
      this.geocodeAddress();
    });
    this.$formkit.get('kiw_form--address_city').on('input', () => {
      this.geocodeAddress();
    });
  },

  data() {
    return {
      center: null,
      apiPromise: null,
      geocoder: null,
      mapOptions: {
        controls: false,
      },
    };
  },

  computed: {},

  methods: {
    geocodeAddress: _debounce(function () {
      this.geocoder.geocode({ address: `${this.$formkit.get('kiw_form--address_street').value} ${this.$formkit.get('kiw_form--address_number').value}, ${this.$formkit.get('kiw_form--postcode').value} ${this.$formkit.get('kiw_form--address_city').value}` }).then(({ results }) => {
        if (results.length) {
          this.center = results[0].geometry.location;
          convertLatLngToLambert(results[0].geometry.location.lat(), results[0].geometry.location.lng())
            .then((r) => r.json())
            .then((result) => {
              this.context.node.input(`x=${result.x}&y=${result.y}`);
            });
        }
      });
    }, 500),
    onMarkerDrop(e) {
      convertLatLngToLambert(e.latLng.lat(), e.latLng.lng())
        .then((r) => r.json())
        .then((result) => {
          this.context.node.input(`x=${result.x}&y=${result.y}`);
        });
    },
  },

  watch: {},
};

async function convertLatLngToLambert(lat, lon) {
  const sourceCrs = '4326'; // WGS84
  const targetCrs = '31370'; // Lambert-93, for example

  const url = `https://epsg.io/trans?x=${lon}&y=${lat}&s_srs=${sourceCrs}&t_srs=${targetCrs}`;

  return fetch(url);
}
