<!-- filename: WebForm.vue -->
<template>
  <div ref="form" :class="[{ 'webform-formkit': true, 'webform-formkit--loading': submissionLoading || loading }, `webform-formkit--${id}`]" @change="onChange">
    <!-- <div v-if="!loading && !success" class="kiw-form__progress">
      {{ progress }}
    </div> -->
    <FormKitSchema v-if="!loading && !success" :schema="schema" :data="formData" :library="cmpLibrary" />
    <div v-if="loading || submissionLoading" class="webform-formkit__loader">
      <!-- TODO: vervangen door loader componentje -->
      <p class="webform-formkit__message">loading</p>
    </div>
    <div v-if="success" class="webform-formkit__success">
      <h5 v-if="successTitle" class="webform-formkit__succes-title">
        {{ successTitle }}
      </h5>
      <div v-if="successMessage" class="webform-formkit__message" v-html="successMessage" />
    </div>
    <ul v-if="showErrorMessage" class="webform-formkit__errors">
      <li v-for="error in errorMessages" :key="error.path" v-html="error.message"></li>
    </ul>
  </div>
</template>

<script src="./Webform.js"></script>
