import InfoTooltip from '../InfoTooltip/InfoTooltip.vue';

export default {
  props: {
    content: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
  },

  components: {
    InfoTooltip,
  },

  mounted() {},

  data() {
    return {};
  },

  computed: {},

  methods: {},

  watch: {},
};
