import { markRaw } from 'vue';
import Webform from '../Webform/Webform.vue';
import MultistepSummary from '../MultistepSummary/MultistepSummary.vue';
import SimpleHTML from '../SimpleHTML/SimpleHTML.vue';

export default {
  props: {},

  components: {
    Webform,
  },

  mounted() {},

  data() {
    return {
      cmpLibrary: markRaw({
        MultistepSummary,
        SimpleHTML,
      }),
      defaults: {},
    };
  },

  computed: {},

  methods: {},

  watch: {},
};
