<template>
  <div :class="{ 'date-selector': true, 'date-selector--grid': true, 'date-selector--selected': activeTitle }">
    <Accordion ref="accordion" :title="activeTitle ? activeTitle : context.attrs.placeholder">
      <template #content>
        <div class="date-selector--grid--date">
          <VueDatePicker v-model="data.date" :locale="'nl-BE'" class="date-picker" inline auto-apply week-start="1" :min-date="min" :enable-time-picker="false" :enable-month-picker="false" hide-offset-dates month-name-format="long" :month-change-on-scroll="false" @update-month-year="onMonthYearChange" @update:model-value="onDateChange">
            <template #arrow-left>
              <div class="slot-icon slot-icon__left" />
            </template>
            <template #arrow-right>
              <div class="slot-icon slot-icon__right" />
            </template>
            <template #action-extra>
              <div v-if="loading" class="dateSelector-loader"><Icon :loading="true" /> Beschikbare data ophalen</div>
            </template>
          </VueDatePicker>
          <div class="date-selector__message">
            <div class="date-select__info" v-if="!tempDate" v-html="context.attrs.notice"></div>
            <div class="date-select__info" v-else-if="tempDate" v-html="context.attrs.warning"></div>
            <button class="button button--secondary" @click.prevent="onConfirmDate" :disabled="!tempDate">Ga verder met deze datum</button>
          </div>
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script src="./DateSelector.js"></script>
