import Accordion from '../../Accordion/Accordion.vue';

export default {
  props: {
    context: {
      type: Object,
    },
  },

  components: {
    Accordion,
  },

  mounted() {},

  data() {
    return {};
  },

  computed: {
    options: {
      get() {
        return this.context.attrs.options.map((option) => ({
          value: option.value,
          title: option.label,
          info: option.help,
        }));
      },
    },
    activeTitle: {
      get() {
        if (!this.value) {
          return null;
        }
        return this.options.filter((option) => option.value === this.value)[0].title;
      },
    },
    value: {
      get() {
        return this.context.value;
      },
      set(val) {
        this.context.node.input(val);
      },
    },
  },

  methods: {
    onRadioChange() {
      this.$refs.accordion.toggle();
    },
  },

  watch: {},
};
