<template>
  <div :class="{ 'kiw-accordion': true, 'kiw-accordion--shown': shown }">
    <div @click.prevent="toggle" class="kiw-accordion__title">
      {{ title }}
      <div class="kiw-accordion__carret"></div>
    </div>
    <div class="kiw-accordion__content">
      <slot name="content">
        <div v-html="content"></div>
      </slot>
    </div>
  </div>
</template>

<script src="./Accordion.js"></script>
