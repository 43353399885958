<!-- eslint-disable -->
<template>
  <div class="formkit-simple-html">
    <div class="formkit-simple-html__content" v-html="content" />
    <InfoTooltip v-if="tooltip" :content="tooltip" />
  </div>
</template>

<script src="./SimpleHTML.js"></script>
<!-- eslint-enable -->
