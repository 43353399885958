import { nl, en, fr } from '@formkit/i18n';
import { createMultiStepPlugin } from '@formkit/addons';
import { createInput } from '@formkit/vue';
import { createProPlugin, datepicker, repeater } from '@formkit/pro';
// import FormKitAddress from './components/FormKit/FormKitAddress/FormKitAddress.vue';
import ImageUpload from './components/Formkit/ImageUpload/ImageUpload.vue';
import DropdownRadios from './components/Formkit/DropdownRadios/DropdownRadios.vue';
import OtherSituationRadios from './components/Formkit/OtherSituationRadios/OtherSituationRadios.vue';
import DateSelector from './components/Formkit/DateSelector/DateSelector.vue';
import LocationFinetune from './components/Formkit/LocationFinetune/LocationFinetune.vue';
// import FormKitPhoneEnhanced from './components/FormKit/FormKitPhoneEnhanced/FormKitPhoneEnhanced.vue';
import { phone } from './rules/phone';
import vat from './rules/vat';

const legends = ['checkbox_multi', 'radio_multi', 'repeater', 'transferlist'];

function customLabelPlugin(node) {
  if (['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(node.props.type)) return;

  node.on('created', () => {
    const legendOrLabel = legends.includes(`${node.props.type}${node.props.options ? '_multi' : ''}`) ? 'legend' : 'label';

    if (node.props.definition.schemaMemoKey) {
      node.props.definition.schemaMemoKey += `${node.props.options ? '_multi' : ''}_add_asterisk`;
    }

    const schemaFn = node.props.definition.schema;

    node.props.definition.schema = (sectionsSchema = {}) => {
      sectionsSchema[legendOrLabel] = {
        children: [
          '$label',
          {
            $el: 'span',
            if: '$state.required',
            attrs: {
              class: 'formkit-asterisk',
            },
            children: ['*'],
          },
          {
            $cmp: 'InfoTooltip',
            if: '$attrs.tooltip',
            props: {
              content: '$attrs.tooltip',
            },
          },
        ],
      };

      return schemaFn(sectionsSchema);
    };
  });
}

function htmlHelpPlugin(node) {
  if (['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(node.props.type)) return;

  node.on('created', () => {
    const schemaFn = node.props.definition.schema;

    node.props.definition.schema = (sectionsSchema = {}) => {
      sectionsSchema.help = {
        children: [
          {
            $cmp: 'div',
            props: {
              innerHTML: '$help',
            },
          },
        ],
      };

      return schemaFn(sectionsSchema);
    };
  });
}

// function autoCompletePlugin (node) {
//     console.log(node);
// }

const proPlugin = createProPlugin('fk-b016e9994d', {
  datepicker,
  repeater,
});

const config = {
  plugins: [
    customLabelPlugin,
    htmlHelpPlugin,
    createMultiStepPlugin({ allowIncomplete: false }),
    proPlugin,
    // autoCompletePlugin,
  ],
  rules: {
    phone,
    vat,
  },
  messages: {
    nl: {
      validation: {
        phone: 'Dit is geen geldig telefoon nummer.',
        required: 'Dit veld is verplicht.',
        vat: 'Dit is geen geldig Btw-nummer.',
      },
    },
    en: {
      validation: {
        phone: 'This is not a valid phone number.',
        required: 'This field is required.',
        vat: 'This is not a valid VAT number.',
      },
    },
    fr: {
      validation: {
        phone: "Ce numéro de téléphone n'est pas valide.",
        required: 'Ce champ est obligatoire.',
        vat: "Ce numéro de TVA n'est pas valide.",
      },
    },
  },
  locales: { nl, en, fr },
  locale: document.documentElement.lang,
  inputs: {
    imageUpload: createInput(ImageUpload),
    dropdownRadios: createInput(DropdownRadios),
    otherSituationRadios: createInput(OtherSituationRadios),
    dateSelector: createInput(DateSelector),
    locationFinetune: createInput(LocationFinetune),
    // addressSelector: createInput(FormKitAddress),
    // recaptcha: createInput(FormKitRecaptcha),
    // phoneEnhanced: createInput(FormKitPhoneEnhanced),
  },
};

export default config;
