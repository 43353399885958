import KIWForm from '../../components/KIWForm/KIWForm.vue';

export default {
  props: {},

  components: {
    KIWForm,
  },

  beforeMount() {},

  mounted() {},

  data() {},

  computed: {},

  methods: {},

  beforeDestroy() {},

  watch: {},
};
