import FileDropzone from 'vue-file-dropzone';
import Accordion from '../../Accordion/Accordion.vue';
import Loader from '../../Loader/Loader.vue';

export default {
  props: {
    context: {
      type: Object,
    },
  },

  components: {
    Accordion,
    FileDropzone,
    Loader,
  },

  mounted() {},

  data() {
    return {
      loading: false,
      imagePreview: null,
      images: [],
      previews: {},
      dropzoneActive: false,
    };
  },

  computed: {
    value() {
      const ids = [];
      this.images.forEach((image) => {
        if (image.id) {
          ids.push(image.id);
        }
      });
      return ids;
    },

    accept: {
      get() {
        const extensions = [];
        this.context.attrs.accept.split(' ').forEach((ext) => {
          extensions.push(`.${ext}`);
        });
        return extensions.join(',');
      },
    },
  },

  methods: {
    onFileInput(e) {
      this.processFiles(Array.from(e.target.files));
    },

    handleDragEnter() {
      this.dropzoneActive = true;
    },

    handleDragLeave() {
      this.dropzoneActive = false;
    },

    handleDrop(ev) {
      this.dropzoneActive = false;
      this.processFiles(Array.from(ev.dataTransfer.files));
    },

    onImageDelete(id) {
      const image = this.images.filter((i) => i.id === id)[0];
      if (image) {
        this.images.splice(this.images.indexOf(image), 1);
      }
    },

    processFiles(files) {
      this.errors = null;
      files.forEach((file) => {
        if (this.images.length >= this.context.attrs.max) return;
        // add image to array so we can show loading state per image
        const image = {
          id: null,
          preview: null,
          type: file.type,
          name: file.name,
        };

        this.images.push(image);

        const formData = new FormData();
        formData.append('files[pictures][]', file);

        fetch(this.context.attrs.api, {
          method: 'POST',
          body: formData,
        })
          .then((r) => r.json())
          .then((fids) => {
            console.log(fids);
            if (fids.pictures) {
              if (file.type.indexOf('image') === 0) {
                const reader = new FileReader();
                reader.onload = (event) => {
                  console.log(event);
                  [image.id] = fids.pictures;
                  image.preview = event.target.result;
                  this.images = [...this.images];
                  this.context.images = [...this.images];
                };
                reader.readAsDataURL(file);
              } else {
                [image.id] = fids.pictures;
                image.preview = true;
                this.images = [...this.images];
                this.context.images = [...this.images];
              }
            } else if (fids.errors) {
              this.images.splice(this.images.indexOf(image), 1);
              this.errors = fids.errors;
            }
          });
      });
    },
  },

  watch: {
    value(val) {
      this.context.node.input(val);
    },
  },
};
