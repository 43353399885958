import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import format from 'date-fns/format';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import { isBefore, startOfDay, isEqual } from 'date-fns';
import { nl } from 'date-fns/locale';
import Accordion from '../../Accordion/Accordion.vue';

export default {
  props: {
    context: {
      type: Object,
    },
  },

  components: {
    VueDatePicker,
    Accordion,
  },

  mounted() {},

  data() {
    return {
      data: {
        date: null,
        timeVal: null,
      },
      tempDate: null,
      fetchedMonths: [],
      availabilities: null,
      currentMonth: getMonth(new Date()),
      currentYear: getYear(new Date()),
      loading: false,
      min: addDays(new Date(), this.context.attrs.min),
    };
  },

  computed: {
    activeTitle: {
      get() {
        return this.data.date && this.context.value ? format(this.data.date, 'dd MMMM yyyy', { locale: nl }) : null;
      },
    },
  },

  methods: {
    handleInput(data) {
      if (isDateWithin8Weeks(data.date)) {
        this.tempDate = data.date;
        this.context.node.input(null);
      } else {
        this.input(data.date);
      }
    },
    input(date) {
      this.tempDate = null;
      const newDate = `${format(date, 'yyy-MM-dd')}`;
      this.context.node.input(newDate);
      this.$refs.accordion.toggle();
    },
    reset() {
      this.availabilities = {};
      this.fetchedMonths = [];
      this.data = {
        date: null,
        timeVal: null,
      };
    },
    onConfirmDate() {
      this.input(this.tempDate);
    },
  },

  watch: {
    data: {
      handler(val) {
        this.handleInput(val);
      },
      deep: true,
      setImmediate: true,
    },
  },
};

// Function to check if a date is within 8 weeks from now
function isDateWithin8Weeks(date) {
  // Get today's date at the start of the day
  const today = startOfDay(new Date());

  // Calculate the date 8 weeks from today
  const dateIn8Weeks = addWeeks(today, 8);

  // Check if the given date is before or equal to the date in 8 weeks
  return isBefore(startOfDay(date), dateIn8Weeks) || isEqual(startOfDay(date), dateIn8Weeks);
}
