import _values from 'lodash/values';
import format from 'date-fns/format';
import { nl } from 'date-fns/locale';

const corKeys = {
  billing_title: 'contact_title',
  billing_first_name: 'contact_first_name',
  billing_name: 'contact_name',
  billing_address_number: 'address_number',
  billing_address_street: 'address_street',
  billing_postcode: 'postcode',
  billing_address_city: 'address_city',
  //
  contact_follow_up_title: 'contact_title',
  contact_follow_up_first_name: 'contact_first_name',
  contact_folluw_up_name: 'contact_name',
  contact_follow_up_email: 'contact_email',
  contact_follow_up_phone: 'contact_phone',
};

export default {
  props: {},

  components: {},

  mounted() {
    if (this.$refs.address_number) {
      const $line = document.createElement('span');
      $line.classList.add('summary__line');
      this.$refs.address_number[0].parentNode.insertBefore($line, this.$refs.address_number[0].nextSibling);
    }

    if (this.$refs.billing_address_street) {
      const $line = document.createElement('span');
      $line.classList.add('summary__line');
      this.$refs.billing_address_street[0].parentNode.insertBefore($line, this.$refs.billing_address_street[0]);
    }
  },

  data() {
    return {
      valuesKey: 0,
      otherSituationSummaryValues: {
        box_street: this.$t('summary.other-situation-values.box-street'),
        box_facade: this.$t('summary.other-situation-values.box-facade'),
        connector_cable: this.$t('summary.other-situation-values.connector-cable'),
        pole: this.$t('summary.other-situation-values.pole'),
      },
    };
  },

  computed: {
    steps: {
      cache: false,
      get() {
        return this.$formkit
          .get('multi_step')
          .children.filter((s) => s.name !== 'summary')
          .map((step) => ({
            id: step.name,
            label: step.props.label,
          }));
      },
    },
    values: {
      get() {
        return this.$formkit.get('kiw_form').value;
      },
    },
  },

  methods: {
    _values,
    onEditClick(id) {
      this.$formkit.get('multi_step').goTo(id);
    },
    processVal(key, value) {
      let val = value;
      switch (key) {
        case 'your_situation':
          if (value === 'facade' || value === 'demolition') {
            val = this.$formkit.get(`kiw_form--${key}`).context.attrs.options.filter((option) => option.value === value)[0].label;
          } else {
            val = null;
          }
          break;
        case 'your_situation_other':
          if (value) {
            val = this.otherSituationSummaryValues[value];
          }
          break;
        case 'date_other':
        case 'date_construction_works':
          if (val) {
            val = `${this.$t('summary.target-date')}: ${format(new Date(val), 'dd MMMM yyyy', { locale: nl })}`;
          }
          break;
        case 'billing_title':
        case 'billing_first_name':
        case 'billing_name':
        case 'billing_address_number':
        case 'billing_address_street':
        case 'billing_postcode':
        case 'billing_address_city':
          if (this.$formkit.get(`kiw_form--billing_same`).value) {
            val = this.$formkit.get(`kiw_form--${corKeys[key]}`).value;
          }
          break;
        case 'contact_follow_up_title':
        case 'contact_follow_up_first_name':
        case 'contact_folluw_up_name':
        case 'contact_follow_up_email':
        case 'contact_follow_up_phone':
          if (this.$formkit.get(`kiw_form--contact_follow_up_self`).value) {
            val = this.$formkit.get(`kiw_form--${corKeys[key]}`).value;
          }
          break;
        case 'contact_follow_up_self':
        case 'billing_same':
        case 'precise_location':
          val = null;
          break;
        case 'pictures':
        case 'plans':
        case 'protected_facade':
          if (this.$formkit.get(`kiw_form--${key}`).context.images) {
            val = this.$formkit.get(`kiw_form--${key}`).context.images;
          } else {
            val = null;
          }
          break;
        default:
          val = value;
          break;
      }
      return val;
    },
  },

  watch: {
    values() {
      this.valuesKey += 1;
    },
  },
};
