<!-- eslint-disable -->
<template>
  <div class="other-situation">
    <div class="other-situation__option" v-for="option in options" :key="option.value">
      <input type="radio" name="situation" @input="onInput" :value="option.value" :id="option.value">
      <label :for="option.value">
        <div class="other-situation__option__image">
          <img :src="`${dir}/${option.value}.jpg`" alt="">
        </div>
        <div>
          <div class="other-situation__option__title">
            {{ option.label }}
          </div>
          <div class="other-situation__option__info">
            {{ option.help }}
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script src="./OtherSituationRadios.js"></script>
<!-- eslint-enable -->
