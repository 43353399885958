import { parsePhoneNumber } from 'libphonenumber-js';
/**
 * A contrived validation rule that ensures the input’s value is a valid phone number.
 */
export const internationalPhone = function (node) {
  if (node.value !== '') {
    try {
      return parsePhoneNumber(node.value).isValid();
    } catch (error) {
      return false;
    }
  } else {
    return true;
  }
};

export const phone = function (node) {
  if (node.value !== '') {
    try {
      return parsePhoneNumber(node.value, 'BE').isValid();
    } catch (error) {
      return false;
    }
  } else {
    return true;
  }
};

export default { internationalPhone, phone };
