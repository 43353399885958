<!-- eslint-disable -->
<template>
  <span class="info-tooltip">
    <Tooltip :autoHide="true" :triggers="['click']" placement="bottom-end">
      <div class="info-tooltip__trigger"></div>
      <template #popper>
        <div v-html="content" />
      </template>
    </Tooltip>
  </span>
</template>

<script src="./InfoTooltip.js"></script>
<!-- eslint-enable -->
