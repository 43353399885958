import { createApp } from 'vue';
import { plugin, defaultConfig } from '@formkit/vue';
import KIWFormApp from './apps/KIWFormApp/KIWFormApp.vue';
import T from './plugins/translate-plugin';
import './sass/main.scss';
import formkitConfig from './formkit.config';
import InfoTooltip from './components/InfoTooltip/InfoTooltip.vue';

const app = createApp(KIWFormApp);
app.use(T);
app.use(plugin, defaultConfig(formkitConfig));
app.component('InfoTooltip', InfoTooltip);
app.mount('#kiw-app');
