<template>
  <div :key="valuesKey" class="summary">
    <div :class="`summary__step summary__step--${step.id}`" v-for="step in steps" :key="step.id">
      <div class="summary__label">{{ step.label }}</div>
      <div class="summary__step__inner">
        <button @click.prevent="onEditClick(step.id)" class="summary__edit"></button>
        <div :ref="key" class="summary__property" v-for="(value, key) in values.multi_step[step.id]" :class="[{ empty: !processVal(key, value) }, `summary__property--${key}`]" :key="key">
          <div class="summary__images" v-if="key === 'pictures' || key === 'plans' || key === 'protected_facade'">
            <div class="summary__img" v-for="img in processVal(key, value)" :key="img.id">
              <img :src="img.preview" alt="" />
            </div>
          </div>
          <div v-else v-html="processVal(key, value)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./MultistepSummary.js"></script>
