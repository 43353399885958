import _keys from 'lodash/keys';
import _values from 'lodash/values';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const apis = {
  'app.wyre.be.dev2.minsky.be': `https://api.wyre.be.dev2.minsky.be/${document.documentElement.lang}`,
  'www.wyre.be.dev2.minsky.be': `https://api.wyre.be.dev2.minsky.be/${document.documentElement.lang}`,
};

const api = apis[window.location.host] ? apis[window.location.host] : `https://loc.wyre-kiw.be/${document.documentElement.lang}`;

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    defaults: {
      type: Object,
    },
    schemeProcessor: {
      type: Function,
    },
    formDataAddons: {
      type: Object,
    },
    cmpLibrary: {
      type: Object,
      default: () => {},
    },
    forceRedirect: {
      type: Object,
      default: {
        bool: false,
      },
    },
  },

  components: {},

  beforeMount() {},

  mounted() {
    this.formData.submit = async (data) => {
      // remove vue values
      delete data.form_id;
      delete data.vApp;
      delete data.webformId;

      // check if data has multi step values + extract them
      _keys(data).forEach((key) => {
        if (key.includes('captcha')) {
          Object.assign(data, data[key]);
          delete data[key];
        }
      });
      // flatten object
      data = flattenObject(data);

      this.submitForm(data);
    };

    // fetch the form
    this.fetchForm();
    // this.loading = false;

    if (this.formDataAddons) {
      this.formData = Object.assign(this.formData, this.formDataAddons);
    }
  },

  data() {
    return {
      otherSchema: [],
      schema: null,
      schemaDefaults: {},
      settings: null,
      loading: true,
      success: false,
      submissionLoading: false,
      loadingMessage: null,
      successMessage: null,
      successTitle: null,
      errorMessages: null,
      showErrorMessage: false,
      multiStep: null,
      formData: {
        values: {},
        filesApi: `${api}/webform/kiw_form/json/upload`,
      },
    };
  },

  emits: ['onStepChange'],

  computed: {
    formKeys: {
      get() {
        return _keys(this.formData.values);
      },
    },
    // progress: {
    //   cache: false,
    //   get() {
    //     let progress = null;
    //     if (this.multiStep) {
    //       progress = `${this.$t('progress.prefix')} ${this.$formkit.get(`${this.id}--${this.multiStep.context.activeStep}`).context.stepIndex + 1} / ${this.multiStep.context.stepCount}`;
    //     }
    //     return progress;
    //   },
    // },
  },

  methods: {
    async fetchForm() {
      // add page params
      const params = new URLSearchParams(window.location.search);
      params.append('schema', 'form_kit');

      fetch(`${api}/webform/${this.id}/json/schema?${params.toString()}`)
        .then((r) => r.json())
        .then((result) => {
          if (result.schema) {
            this.schema = this.schemeProcessor ? this.schemeProcessor(result.schema) : result.schema;
            this.schemaDefaults = result.values;
          } else {
            this.errorMessage = 'Dit formulier ontbreekt.';
            this.showErrorMessage = true;
          }

          this.loading = false;

          this.$nextTick(() => {
            this.multiStep = this.$formkit.get(`multi_step`);
            window.situationField = this.$formkit.get('kiw_form--your_situation');
          });
        });
    },

    setDefaults() {
      _keys(this.defaults).forEach((key) => {
        if (this.$formkit.get(`${this.id}--${key}`)) {
          this.$formkit.get(`${this.id}--${key}`).context.node.input(this.defaults[key]);
        }
      });

      _keys(this.schemaDefaults).forEach((key) => {
        if (this.$formkit.get(`${this.id}--${key}`)) {
          this.$formkit.get(`${this.id}--${key}`).context.node.input(this.schemaDefaults[key]);
        }
      });
    },

    async submitForm(data) {
      /* eslint-disable */
      this.submissionLoading = true;

      // disable submit button
      const submitButton = document.querySelector('.formkit-input[type=submit]');
      if (submitButton) {
        submitButton.disabled = true;
      }

      // add page params
      const params = new URLSearchParams(window.location.search);
      params.append('schema', 'form_kit');

      // convert data to formdata
      const formData = new FormData();
      for (const key in data) {
        // only add to formdata if has value
        if (hasValue(data[key])) {
            if (Array.isArray(data[key])) {
                data[key].forEach((val) => {
                    if(typeof val === 'object') {
                      formData.append(`${key}[]`, _values(val)[0]);
                    } else {
                      formData.append(`${key}[]`, val);
                    }
                });
            } else if(typeof data[key] === 'string') {
                if(data[key].indexOf('json_value') === 2) {
                    const values = JSON.parse(data[key]).json_value;
                    _keys(values).forEach(subkey => {
                        formData.append(`${key}[${subkey}]`, values[subkey]);
                    })
                } else {
                    formData.append(key, data[key]);
                }
            }
            else {
                formData.append(key, data[key]);
            }
        }
      }
      /* eslint-enable */

      fetch(`${api}/webform/${this.id}/json/submission?${params.toString()}`, {
        method: 'POST',
        body: formData,
        query: window.location.search,
      })
        .then((r) => {
          if (r.status === 200) {
            r.json().then((response) => {
              this.handleSuccess(response);
            });
          } else {
            r.json().then((response) => {
              this.showErrorMessage = true;
              this.submissionLoading = false;
              this.errorMessages = response.errors;
            });
          }
        })
        .catch(() => {
          this.showErrorMessage = true;
          this.submissionLoading = false;
        });
    },

    handleSuccess(response) {
      console.log(response);
      if (!this.forceRedirect.bool) {
        switch (response.confirmation.type) {
          case 'inline':
            this.successTitle = response.confirmation.title;
            this.successMessage = response.confirmation.message;
            this.success = true;
            // remove toggle
            this.submissionLoading = false;
            break;
          case 'url':
            window.location = response.confirmation.url;
            break;
          default:
            this.successTitle = 'Bedankt!';
            this.successMessage = 'We hebben je gegevens goed ontvangen.';
            this.success = true;
            // remove toggle
            this.submissionLoading = false;
            break;
        }

        // scroll to success
        gsap.to(window, {
          duration: 0.5,
          scrollTo: { y: this.$refs.form, offsetY: 200 },
          ease: 'power2',
        });
      } else {
        window.location = this.forceRedirect.url;
      }
    },
  },

  watch: {
    formKeys() {
      this.setDefaults();
    },
  },
};

function flattenObject(obj) {
  const flattened = {};

  function flatten(_obj, parentKey = '') {
    for (const key in _obj) {
      if (Array.isArray(_obj[key])) {
        flattened[key] = _obj[key];
      } else if (typeof _obj[key] === 'object' && _obj[key] !== null) {
        flatten(_obj[key], `${parentKey + key}.`);
      } else {
        flattened[key] = _obj[key];
      }
    }
  }

  flatten(obj);

  console.log(flattened);

  return flattened;
}

function hasValue(val) {
  let _hasValue = false;
  if (val || val === 0) {
    _hasValue = true;
  }

  if (Array.isArray(val)) {
    _hasValue = Boolean(val.length);
  }

  return _hasValue;
}
