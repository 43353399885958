<!-- eslint-disable -->
<template>
  <div :class="{ 'dropdown-radios': true, 'dropdown-radios--selected': value }">
    <Accordion ref="accordion" :title="activeTitle ? activeTitle : context.attrs.placeholder">
      <template #content>
        <div class="dropdown-radios__radio" v-for="option in options" :key="option.value">
          <input v-model="value" @input="onRadioChange" :value="option.value" name="radio"
            :id="`kiw-form-radio-${option.value}`" type="radio">
          <label :for="`kiw-form-radio-${option.value}`">
            <div class="dropdown-radios__radio__title">{{ option.title }}</div>
            <div class="dropdown-radios__radio__info">{{ option.info }}</div>
          </label>
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script src="./DropdownRadios.js"></script>
<!-- eslint-enable -->
