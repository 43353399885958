import { Tooltip } from 'floating-vue';

export default {
  props: {
    content: {
      type: String,
    },
  },

  components: {
    Tooltip,
  },

  mounted() {
    console.log(this.content);
  },

  data() {
    return {};
  },

  computed: {},

  methods: {},

  watch: {},
};
