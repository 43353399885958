export default {
  props: {
    context: {
      type: Object,
    },
  },

  components: {},

  mounted() {},

  data() {
    return {
      dir: window.location.hostname === 'localhost' ? '../../assets/images' : '/modules/custom/kiw_form/assets/images',
    };
  },

  computed: {
    options: {
      get() {
        return this.context.attrs.options;
      },
    },
  },

  methods: {
    onInput(e) {
      this.context.node.input(e.currentTarget.value);
    },
  },

  watch: {},
};
