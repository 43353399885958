<!-- eslint-disable -->
<template>
  <div :key="context.attrs.key" class="image-upload">
    <div class="image-upload__wrapper">
        <label :for="context.node.name" class="image-upload__label">
          <div :class="{'image-upload__dropzone': true, 'image-upload__dropzone--active': dropzoneActive}" @dragenter.self="handleDragEnter" @dragover.prevent @dragleave.self="handleDragLeave" @drop.prevent="handleDrop">
            <div v-if="!loading && !images.length" class="image-upload__placeholder"></div>
            <div v-else-if="!loading && images.length" class="image-upload__previews">
              <div v-for="image in images" :key="image.id" :class="{'image-upload__preview': true, 'image-upload__preview--loading': !image.preview}">
                <a href="#" @click.prevent="onImageDelete(image.id)" class="image-upload__delete"></a>
                <img v-if="image.preview && image.type.indexOf('image') === 0" :src="image.preview">
                <div class="image-upload__preview__text" v-else-if="image.preview && image.type.indexOf('image') < 0">{{ image.name }}</div>
                <Loader v-else />
              </div>
              <div v-if="!loading && images.length < 5" class="image-upload__placeholder"></div>
            </div>
            <div class="image-upload__info">
              <div v-if="!images.length" class="image-upload__description" v-html="context.attrs.description"></div>
              <div v-if="!images.length" class="image-upload__accept">{{ context.attrs.accept }}</div>
            </div>
          </div>
        </label>
        <input @input="onFileInput" :name="context.node.name" multiple="true" type="file" :id="context.node.name" :accept="accept" class="image-upload__input"/>
    </div>
    <ul v-if="errors" class="image-upload__errors">
      <li v-for="error in errors" :key="error.message" v-html="error.message"></li>
    </ul>
    <Accordion v-if="context.attrs.more_title && context.attrs.more" :title="context.attrs.more_title" :content="context.attrs.more" />
  </div>
</template>

<script src="./ImageUpload.js"></script>
<!-- eslint-enable -->
