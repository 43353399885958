export default {
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },

  components: {},

  mounted() {},

  data() {
    return {
      shown: false,
    };
  },

  computed: {},

  methods: {
    toggle() {
      this.shown = !this.shown;
    },
  },

  watch: {},
};
