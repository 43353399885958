<!-- eslint-disable -->
<template>
  <div class="location-finetune">
    {{ address }}
    <GoogleMap
      v-if="apiPromise && center"
      :api-key="apiPromise"
      :center="center"
      :zoom="19"
      :fullscreenControl="false"
      :mapTypeControl="false"
      :streetViewControl="false"
    >
      <Marker :options="{ position: center, draggable: true }" @dragend="onMarkerDrop" />
    </GoogleMap>
    <Loader v-else />
  </div>
</template>

<script src="./LocationFinetune.js"></script>
<!-- eslint-enable -->
